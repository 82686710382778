import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../layout'
import PostListing from '../components/PostListing'
import SEO from '../components/SEO'
import config from '../../data/SiteConfig'
import video from '../images/costroute.gif'

class Index extends Component {
  render() {
    const latestPostEdges = this.props.data.latest.edges
    const popularPostEdges = this.props.data.popular.edges

    return (
      <Layout>
        <Helmet title={`${config.siteTitle} – Simulation Modeling`} />
        <SEO />
        <div className="container">
          <div className="lead">
           <img src={video} className="modelvideo" />
            <b>Welcome!</b> <br />
            <br />
            My name is Florian Hübler, I am a simulation modeler. <br />
            On this site I want to share my simulation experiences and interesting code.<br />
            Although I work with a handful of tools, my main focus is on <a href="https://anylogic.com">AnyLogic</a>.<br />
            <br />
            Feel free to explore the <a href="blog">blog entries</a> and <a href="me">contact me</a> if you have questions or feedback about the blog!
          </div>
        </div>

        <div className="container">
           <section className="section">
            <h2>Latest</h2>
            <PostListing simple postEdges={latestPostEdges} />
          </section>

{         <section className="section">
            <h2>Most Popular</h2>
            <PostListing simple postEdges={popularPostEdges} />
          </section> }

        </div>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 3
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    popular: allMarkdownRemark(
      limit: 3
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
  }
`
